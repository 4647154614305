import { createTheme } from "@mui/material/styles";
import typography from "./typography";

const primaryMain = "#85CFFF";
const theme = createTheme({
  typography,
  palette: {
    mode: "dark",
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: "#56DF87",
    },
    error: {
      main: "#FFB4A6",
    },
    secondaryContainer: {
      main: "#005228",
    },
    primaryContainer: {
      main: "#004C6C",
    },
    primaryFaded: {
      main: "#24a9ff",
    },
    primaryVeryFaded: {
      main: "#0076c2",
    },
    inverseText: { main: "#181818" },

    neutralBackground: {
      main: "#666666",
    },
    hoverBackground: {
      main: "#005788",
    },
    themeText: {
      main: "#000000",
      light: "#3E3E3E",
      dark: "#101010",
    },
    infoBox: {
      main: "#b0aeae",
    },
  },
});

theme.components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: "#004C6C",
        color: "#000",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        backgroundColor: "#bbbbbb",
        color: "#000000",
        textAlign: "left",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        backgroundColor: "#222222",
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        backgroundColor: "#222222",
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        backgroundColor: "#222222",
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        // don't force capitalization
        textTransform: "none",
        borderRadius: 8,
      },
    },
  },
};

export default theme;
