import React, { useEffect, useState } from "react";
import { useIntl, type IntlShape } from "react-intl";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { SvgIconComponent } from "@mui/icons-material";
import { textChangeHandler } from "../utils/eventUtils";
import { type BinSet, visitBins } from "../hooks/useAudioProcessing";
import { AssessmentInput } from "../gql/graphql";
import AmchurCard from "./AmchurCard";

function binTitle(intl: IntlShape, bin: string) {
  // ideally all names should go through i18n, but for now just the ones where
  // we want to rename the field coming from the model.
  if (["drugs", "a_p"].includes(bin)) {
    return intl.formatMessage({ id: `bin.title.${bin}` });
  } else {
    return bin;
  }
}

//  data is coming (from the ML model) in form of an object with keys containing bin's name and value correponding to an array of objects, where each object in array has key and value of type string, in case a bin has no corresponding data it's value will be an empty array
export interface BinCardStackProps {
  binSet: BinSet;
  revisedBinSet?: BinSet;
  updateAssessment?: (assessment: AssessmentInput) => void;
  assessmentReady?: boolean;
  isEditable?: boolean;
  isComparable?: boolean;
}
function BinCardStack({
  binSet,
  revisedBinSet,
  updateAssessment,
  assessmentReady = true,
  isEditable = false,
  isComparable = false,
}: BinCardStackProps) {
  // const intl = useIntl();
  // console.log("complete binSet", binSet);
  const revisedBins = revisedBinSet === undefined ? binSet : revisedBinSet;
  return (
    <>
      {visitBins.map((bin) => (
        <React.Fragment key={bin}>
          <BinCard
            bin={binSet[bin] || "[]"}
            revisedBin={revisedBins[bin] || "[]"}
            title={bin}
            updateAssessment={updateAssessment}
            assessmentReady={assessmentReady}
            isEditable={isEditable}
            isComparable={isComparable}
          />
        </React.Fragment>
      ))}
    </>
  );
}
interface BinCardProps {
  bin: string;
  revisedBin: string;
  title: string;
  updateAssessment?: (assessment: AssessmentInput) => void;
  assessmentReady: boolean;
  isEditable: boolean;
  isComparable: boolean;
}
function BinCard({
  bin,
  revisedBin,
  title,
  updateAssessment = () => {},
  assessmentReady,
  isEditable,
  isComparable,
}: BinCardProps) {
  const intl = useIntl();
  const [inEditMode, setEditMode] = useState(false);
  const [inCompMode, setCompMode] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [binState, setBinState] = useState<any>([]);
  useEffect(() => {
    // bin contain original bin data while revisedBin contains modified one
    const binData = JSON.parse(revisedBin);
    setBinState(binData);
  }, [revisedBin, title]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateBin = (currState: { [key: string]: any }[]) => {
    const updatedBin = { [title]: JSON.stringify(currState) };
    console.log("updatedBin", updatedBin);
    updateAssessment(updatedBin);
  };
  const handleEditField = (
    index: number,
    updatedObj: { [key: string]: string }
  ) => {
    console.log("about to update Editted Field");
    const newState = [...binState];
    newState[index] = updatedObj;
    console.log("new updated field", newState);
    updateBin(newState);
    setBinState(newState);
  };
  const handleDeleteField = (index: number, key: string) => {
    console.log(
      "handle delete",
      index,
      key,
      "binState",
      [...binState],
      "old way",
      binState
    );
    let newState = [...binState];
    delete newState[index][key];
    newState = newState.filter((obj) => JSON.stringify(obj) !== "{}"); // removing any empty object
    console.log("newState", newState);
    updateBin(newState);
    setBinState(newState);
  };
  const handleNewField = (key: string, value: string) => {
    const newState = [...binState, { [key]: value }];
    updateBin(newState);
    setBinState(newState);
  };
  return (
    <AmchurCard
      title={binTitle(intl, title)}
      role="secondary"
      allowEdit={isEditable}
      inEditMode={inEditMode}
      onEditStateChanged={setEditMode}
      allowComparison={isComparable}
      inCompMode={inCompMode}
      onCompModeChanged={setCompMode}
    >
      {inCompMode ? (
        <BinInfoView
          key={"original"}
          bin={JSON.parse(bin)}
          inEditMode={false}
          inCompMode={inCompMode}
          onEdit={handleEditField}
          onDelete={handleDeleteField}
          onAdd={handleNewField}
          assessmentReady={assessmentReady}
        />
      ) : (
        <BinInfoView
          key={"revised"}
          bin={binState}
          inEditMode={inEditMode}
          inCompMode={inCompMode}
          onEdit={handleEditField}
          onDelete={handleDeleteField}
          onAdd={handleNewField}
          assessmentReady={assessmentReady}
        />
      )}
    </AmchurCard>
  );
}

interface BinInfoViewProps {
  bin: (Record<string, string> | string)[];
  inEditMode: boolean;
  inCompMode: boolean;
  onEdit: (index: number, obj: { [key: string]: string }) => void;
  onDelete: (index: number, key: string) => void;
  onAdd: (key: string, value: string) => void;
  assessmentReady: boolean;
}

function BinInfoView({
  bin,
  inEditMode,
  inCompMode,
  onEdit,
  onDelete,
  onAdd,
  assessmentReady,
}: BinInfoViewProps) {
  const intl = useIntl();
  if (bin.length > 0) {
    return (
      <Stack gap={2}>
        {bin.map((item, index) => (
          <BinItemView
            bin={item}
            key={index}
            index={index}
            inEditMode={inEditMode}
            inCompMode={inCompMode}
            updateField={onEdit}
            deleteField={onDelete}
          />
        ))}
        {inEditMode && <AddBinItem addField={onAdd} />}
      </Stack>
    );
  } else {
    // for bin with no data from ML model
    return (
      <>
        <BinItemView
          bin={
            assessmentReady
              ? intl.formatMessage({ id: "bin.notMentioned" })
              : ""
          }
          index={-1}
          inEditMode={inEditMode}
          inCompMode={inCompMode}
          updateField={onEdit}
          deleteField={onDelete}
        />
        {inEditMode && <AddBinItem addField={onAdd} />}
      </>
    );
  }
}

interface BinItemViewProps {
  bin: Record<string, string> | string;
  index: number;
  inEditMode: boolean;
  inCompMode: boolean;
  updateField: (index: number, obj: { [key: string]: string }) => void;
  deleteField: (index: number, key: string) => void;
}
function BinItemView({
  bin,
  index,
  inEditMode,
  inCompMode,
  updateField,
  deleteField,
}: BinItemViewProps) {
  const [itemState, setItemState] = useState<{ [key: string]: string }>({});
  useEffect(() => {
    // ignoring any string data
    if (typeof bin === "object" && bin !== null) {
      setItemState(bin);
    }
  }, [bin]);

  useEffect(() => {
    if (
      !inEditMode &&
      Object.keys(itemState).length !== 0 &&
      JSON.stringify(itemState) !== JSON.stringify(bin)
    ) {
      console.log("itemState", itemState);
      updateField(index, itemState);
    }
  }, [inEditMode, bin, index, itemState, updateField]);

  const onStateChange = (key: string, value: string) => {
    console.log("value change being callled");
    const newState = { ...itemState, [key]: value };
    console.log("newState", newState);
    setItemState(newState);
  };

  if (inCompMode) {
    return <NonEditableBinItemView bin={bin} />;
  }
  if (inEditMode) {
    return (
      <EditbaleBinItemView
        itemState={itemState}
        index={index}
        updateState={onStateChange}
        deleteField={deleteField}
      />
    );
  } else {
    return <NonEditableBinItemView bin={bin} />;
  }
}

function NonEditableBinItemView({
  bin,
}: {
  bin: Record<string, string> | string;
}) {
  const intl = useIntl();
  if (typeof bin == "string") {
    return <Typography textAlign="left">{bin}</Typography>;
  }
  return (
    <Stack>
      {Object.keys(bin).map((key) => (
        <Typography textAlign="left" key={key}>
          {key}:{bin[key] || intl.formatMessage({ id: "bin.notMentioned" })}
        </Typography>
      ))}
    </Stack>
  );
}

interface EditbaleBinItemViewProps {
  index: number;
  itemState: { [key: string]: string };
  updateState: (key: string, value: string) => void;
  deleteField: (index: number, key: string) => void;
}
function EditbaleBinItemView({
  index,
  itemState,
  updateState,
  deleteField,
}: EditbaleBinItemViewProps) {
  return (
    <Stack>
      {Object.keys(itemState).map((key) => (
        <TextField
          size="small"
          variant="filled"
          key={key}
          label={key}
          value={itemState[key]}
          onChange={(e) => updateState(key, e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    console.log("itemState", itemState);
                    deleteField(index, key);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      ))}
    </Stack>
  );
}

function AddBinItem({
  addField,
}: {
  addField: (key: string, value: string) => void;
}) {
  const [formToggle, setFormToggle] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const intl = useIntl();

  const toggleForm = () => {
    setFormToggle(!formToggle);
  };
  const handleNewField = () => {
    if (title && description) {
      addField(title, description);
      setTitle("");
      setDescription("");
      toggleForm();
    }
  };
  const readyToSubmit = title.length > 0 && description.length > 0;
  return (
    <Stack gap={2}>
      <BinButton
        Icon={AddIcon}
        text={intl.formatMessage({ id: "bin.addField" })}
        width={1 / 2}
        onClick={toggleForm}
      />
      {formToggle && (
        <Stack gap={2}>
          <TextField
            required={true}
            size="small"
            label="title"
            value={title}
            onChange={textChangeHandler(setTitle)}
          ></TextField>
          <TextField
            required={true}
            size="small"
            label="description"
            value={description}
            onChange={textChangeHandler(setDescription)}
          ></TextField>
          <BinButton
            disabled={!readyToSubmit}
            text={intl.formatMessage({ id: "common.ok" })}
            isPrimary={true}
            width={1 / 5}
            onClick={handleNewField}
          />
        </Stack>
      )}
    </Stack>
  );
}

interface BinButtonProps {
  text: string;
  Icon?: SvgIconComponent;
  width: number;
  isPrimary?: boolean;
  disabled?: boolean;
  onClick: () => void;
}
function BinButton({
  text,
  Icon,
  width,
  disabled = false,
  isPrimary = false,
  onClick,
}: BinButtonProps) {
  return (
    <Button
      variant="contained"
      color={isPrimary ? "primary" : "secondary"}
      sx={{ width: width, margin: "auto" }}
      endIcon={Icon && <Icon />}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
export default BinCardStack;
