import { FormattedMessage, useIntl } from "react-intl";
import { ErrorResponse } from "@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes";
import { SerializedError } from "@reduxjs/toolkit";
import MediaQuery from "react-responsive";
import { forwardRef, useCallback, useRef, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
//import { enqueueSnackbar } from "notistack";
//import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import useAudioProcessing, { BinSet } from "../../hooks/useAudioProcessing";
import { type RootState } from "../../state/reducers";
import useCurrentClinicId from "../../hooks/useCurrentClinic";
import { TranscriptionSection } from "../../hooks/useTranscriptionState";
import useScrollWithOutput from "../../hooks/useScrollWithOutputHook";
import SehatSpinner from "../../components/SehatSpinner";
import useActiveVisit from "../../hooks/useActiveVisit";
import RecordingButtonRow from "../../components/RecordingButtonRow";
import useCurrentHealthOrg from "../../hooks/useCurrentHealthOrg";
import DiagnosisPanel from "../../components/DiagnosisPanel";
import {
  InitiateVisitInput,
  InitiateVisitMutation,
  useInitiateVisitMutation,
  useUpdateVisitAssessmentMutation,
  useUpdateVisitNotesMutation,
} from "../../graphql/generated";
import { textChangeHandler } from "../../utils/eventUtils";
import { useServiceBag } from "../../services/ServiceBag";
import { setVisit } from "../../state/visitSlice";
import BinCardStack from "../../components/BinCardStack";
import { updateNotes } from "../../state/assessmentSlice";
import { AssessmentInput } from "../../gql/graphql";
import TranscriptionPanel, {
  type TranscriptionPanelProps,
} from "./TranscriptionPanel";

interface InitiateVisitResult {
  data?: InitiateVisitMutation;
  error?: ErrorResponse | SerializedError;
}

// simplified version of VisitInProgress for earlier release
// no vitals, or medication info, etc. -- just transcription and notes.

function SimpleVisitInProgressRoute() {
  const { envService } = useServiceBag();
  const clinicId = useCurrentClinicId();
  const recordingOnly = useCurrentHealthOrg()?.trainingOnly || false;
  const [initiateVisit] = useInitiateVisitMutation();
  const scrollRef = useRef<HTMLUListElement>(null);
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootState) => state.currentUser.currentUser?.email
  );

  useScrollWithOutput(scrollRef);

  const { visitId, needToLoadVisit } = useActiveVisit(clinicId);
  const [visitToken, setVisitToken] = useState("");
  const [pressedStop, setPressedStop] = useState(false);

  const [saveNotes] = useUpdateVisitNotesMutation();
  const [updateAssessment] = useUpdateVisitAssessmentMutation();

  const createVisitFromToken = useCallback(() => {
    const visitInfo: InitiateVisitInput = {
      visitId: envService.randomUUID(),
      clinicId,
      patientId: visitToken,
      patientIdType: "TOKEN",
      createdAt: envService.currentTime(),
      assocPhysician: currentUser,
    };
    dispatch(setVisit({ visit: visitInfo }));
    return initiateVisit({ visitInfo }) as Promise<InitiateVisitResult>;
  }, [clinicId, dispatch, currentUser, envService, initiateVisit, visitToken]);

  // const onDone = useCallback(() => {
  //   navigate("/plan");
  // }, [navigate]);

  const onDone = useCallback(() => {
    window.location.reload();
  }, []);

  const {
    recordingInProgress,
    transcription,
    questions,
    bins,
    startProcessing,
    stopProcessing,
    audioSessionId,
    waitingForAssessment,
    assessmentReady,
  } = useAudioProcessing(clinicId, visitId);

  const onStart = useCallback(() => {
    async function onStartAsync() {
      const token = await createVisitFromToken();
      if (token.data) {
        startProcessing(token.data?.initiateVisit?.visitId);
      } else {
        console.log(`failed to create visit: ${token.error?.message || "?"}`);
        enqueueSnackbar("Failed to start visit");
      }
    }
    if (recordingOnly) {
      console.log("start processing for recordig only");
      startProcessing();
    } else {
      console.log("start processing with visit");
      void onStartAsync();
    }
  }, [createVisitFromToken, recordingOnly, startProcessing]);

  const onStop = useCallback(() => {
    setPressedStop(true);
    stopProcessing();
  }, [stopProcessing, setPressedStop]);

  const readyToStartRecording = audioSessionId.length > 0;
  const { notes, revisedNotes, symptoms } = useSelector((state: RootState) => {
    return state.assessment;
  });

  console.log(`now my notes are ${notes || "---"}`);

  const onAssessmentUpdate = useCallback(
    (assessment: AssessmentInput) => {
      console.log("about to update Assessment\n", assessment);
      if (visitId) {
        updateAssessment({ visitId, clinicId, assessment })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => console.log(error));
      }
    },
    [updateAssessment, visitId, clinicId]
  );
  // onAssessmentUpdate should be used for updating the notes as well,
  // but we can deal with it later
  const onUpdateNotes = useCallback(
    (revisedNotes: string) => {
      if (visitId) {
        const visit = { revisedNotes };
        void saveNotes({
          visitId,
          clinicId,
          visit,
        });
      }
      console.log(`updating notes to be ${revisedNotes}`);
      if (revisedNotes) {
        dispatch(updateNotes(revisedNotes));
      }
    },
    [clinicId, dispatch, saveNotes, visitId]
  );

  if (needToLoadVisit) {
    return <SehatSpinner />;
  }

  return (
    <SimpleVisitInProgressUiView
      onStart={onStart}
      onStop={onStop}
      onDone={onDone}
      pressedStop={pressedStop}
      transcription={transcription}
      questions={questions}
      recordingInProgress={recordingInProgress}
      readyToStart={readyToStartRecording}
      planReady={assessmentReady}
      waitingForAssessment={waitingForAssessment}
      ref={scrollRef}
      symptoms={symptoms}
      notes={notes}
      revisedNotes={revisedNotes}
      bins={bins}
      recordingOnly={recordingOnly}
      visitToken={visitToken}
      setVisitToken={setVisitToken}
      updateNotes={onUpdateNotes}
      updateAssessment={onAssessmentUpdate}
    />
  );
}

interface SimpleVisitInProgressUiViewProps {
  onStart: () => void;
  onStop: () => void;
  onDone: () => void;
  pressedStop: boolean;
  recordingInProgress: boolean;
  planReady: boolean;
  waitingForAssessment: boolean;
  readyToStart: boolean;
  transcription: TranscriptionSection[];
  questions: string[] | null;
  bins: BinSet;
  symptoms: string[];
  notes?: string;
  revisedNotes?: string;
  recordingOnly?: boolean;
  visitToken: string;
  setVisitToken: (visitToken: string) => void;
  updateNotes: (s: string) => void;
  updateAssessment: (assessment: AssessmentInput) => void;
}

function SimpleVisitInProgressUiViewInternal(
  {
    recordingOnly,
    onStart,
    onStop,
    onDone,
    pressedStop,
    recordingInProgress,
    transcription,
    questions,
    bins,
    readyToStart,
    planReady,
    waitingForAssessment,
    symptoms,
    notes,
    revisedNotes,
    visitToken,
    setVisitToken,
    updateNotes,
    updateAssessment,
  }: SimpleVisitInProgressUiViewProps,
  ref: React.ForwardedRef<HTMLUListElement>
) {
  const intl = useIntl();
  const readyToRecord = visitToken !== "";
  return (
    <Stack spacing="1em" ml="1em" height="100%">
      {recordingOnly && (
        <Typography variant="h4">Recording for Training Purposes</Typography>
      )}

      <Stack direction="row">
        <TextField
          label={intl.formatMessage({ id: "visitInProgress.identifier" })}
          value={visitToken}
          sx={{ width: "20em", marginTop: "10px" }}
          required={true}
          onChange={textChangeHandler(setVisitToken)}
        />
      </Stack>
      <RecordingButtonRow
        onStart={onStart}
        onStop={onStop}
        pressedStop={pressedStop}
        recordingInProgress={recordingInProgress}
        readyToStart={readyToRecord}
        waitingForModel={!readyToStart}
        enableDone={planReady}
      />
      <SimpleVisitInfoPanel
        transcription={transcription}
        transcriptionHeight="100%"
        transcriptionListHeight="100%"
        transcriptionInProgress={recordingInProgress}
        notes={notes}
        revisedNotes={revisedNotes}
        symptoms={symptoms}
        bins={bins}
        onDone={onDone}
        enableDone={planReady}
        waiting={waitingForAssessment}
        updateNotes={updateNotes}
        updateAssessment={updateAssessment}
      />
    </Stack>
  );
}

const SimpleVisitInProgressUiView = forwardRef(
  SimpleVisitInProgressUiViewInternal
);

type SimpleVisitInfoPanelProps = TranscriptionPanelProps &
  VisitDoneButtonProps & {
    notes?: string;
    revisedNotes?: string;
    updateNotes: (notes: string) => void;
    symptoms: string[];
    waiting: boolean;
    bins: BinSet;
    updateAssessment: (assessment: AssessmentInput) => void;
  };

function SimpleVisitInfoPanel({
  notes,
  revisedNotes,
  symptoms,
  onDone,
  enableDone,
  waiting,
  bins,
  updateNotes,
  updateAssessment,
  ...transcriptionArgs
}: SimpleVisitInfoPanelProps) {
  const [tabState, setTabState] = useState("notes");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabState(newValue);
  };
  return (
    <>
      <MediaQuery minWidth={1024}>
        <Stack direction="row" spacing="20px" mx="10px">
          <Box width="45%">
            <TranscriptionPanel {...transcriptionArgs} />
          </Box>
          <Stack direction="column" width="45%" sx={{ rowGap: 2 }}>
            <DiagnosisPanel
              notes={notes || ""}
              revisedNotes={revisedNotes || ""}
              symptoms={symptoms}
              waiting={waiting}
              updateNotes={updateNotes}
            />
            <BinCardStack
              binSet={bins}
              revisedBinSet={bins}
              updateAssessment={updateAssessment}
              assessmentReady={enableDone}
              isEditable={true}
              isComparable={true}
            />
            <VisitDoneButton onDone={onDone} enableDone={enableDone} />
          </Stack>
        </Stack>
      </MediaQuery>
      <MediaQuery maxWidth={1023}>
        <Stack spacing="10px" width="80%">
          <Tabs
            value={tabState}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Transcription" value="transcript" />
            <Tab label="Notes" value="notes" />
          </Tabs>
          {tabState === "transcript" && (
            <TranscriptionPanel {...transcriptionArgs} />
          )}
          {tabState === "notes" && (
            <DiagnosisPanel
              notes={notes || ""}
              revisedNotes={revisedNotes || ""}
              symptoms={symptoms}
              waiting={waiting}
              updateNotes={updateNotes}
            />
          )}
          <VisitDoneButton onDone={onDone} enableDone={enableDone} />
        </Stack>
      </MediaQuery>
    </>
  );
}

interface VisitDoneButtonProps {
  onDone: () => void;
  enableDone: boolean;
}
function VisitDoneButton({ onDone, enableDone }: VisitDoneButtonProps) {
  return (
    <Box my="10px">
      <Button
        sx={{ ml: "2em" }}
        variant="contained"
        onClick={onDone}
        disabled={!enableDone}
      >
        <FormattedMessage id="visitInProgress.visitDone" />
      </Button>
    </Box>
  );
}
export default SimpleVisitInProgressRoute;
