import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import PlayIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import { SmallSehatSpinner } from "./SehatSpinner";

interface ButtonRowProps {
  onStart: () => void;
  onStop: () => void;
  recordingInProgress: boolean;
  readyToStart: boolean;
  waitingForModel: boolean;
  pressedStop?: boolean;
  onDone?: () => void;
  enableDone?: boolean;
}
function ButtonRow({
  onStart,
  onStop,
  pressedStop,
  recordingInProgress,
  readyToStart,
  waitingForModel,
  onDone,
  enableDone,
}: ButtonRowProps) {
  console.log(
    "recordingInProgress",
    recordingInProgress,
    "pressedStop",
    pressedStop
  );
  const playButtonAppearance =
    waitingForModel && readyToStart ? (
      <SmallSehatSpinner size={26} />
    ) : (
      <>
        <PlayIcon />
        Start
      </>
    );

  return (
    <Stack direction="row" spacing="1em">
      <Button
        variant="contained"
        onClick={onStart}
        color="secondary"
        disabled={
          recordingInProgress || !readyToStart || pressedStop || waitingForModel
        }
      >
        {playButtonAppearance}
      </Button>

      <Button
        variant="contained"
        onClick={onStop}
        disabled={!recordingInProgress || pressedStop}
      >
        <StopIcon />
        Stop
      </Button>

      {onDone && (
        <Button
          sx={{ ml: "2em" }}
          variant="contained"
          onClick={onDone}
          disabled={!enableDone}
        >
          Done
        </Button>
      )}
    </Stack>
  );
}

export default ButtonRow;
