import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const ErrorPage = () => {
  return (
    <Stack spacing={4}>
      <Typography variant="h2">404</Typography>
      <Typography variant="h6">This page does not exist.</Typography>
      <Typography variant="h6">
        If this is a medical emergency dial 911.
      </Typography>
    </Stack>
  );
};

export default ErrorPage;
