import Box from "@mui/material/Box";
import SehatSpinnerIcon from "../icons/sehat-smooth.gif";

function SehatSpinner() {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img
        src={SehatSpinnerIcon}
        alt="Sehat Spinner"
        height="128px"
        width="128px"
      />
    </div>
  );
}

export function SmallSehatSpinner({ size = 64 }: { size?: number }) {
  const sizeString = `${size}px`;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        src={SehatSpinnerIcon}
        alt="Sehat Spinner"
        height={sizeString}
        width={sizeString}
      />
    </Box>
  );
}

export default SehatSpinner;
